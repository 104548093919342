
import { defineComponent } from "vue";
import MyNavigation from "@/components/MyNavigation.vue";

export default defineComponent({
  name: "App",
  metaInfo: {
    title: "Plan your AcroYoga life",
    // all titles will be injected into this template
    titleTemplate: "%s | PlanMyAcroYoga",
  },
  components: {
    MyNavigation,
  },
  data() {
    return {};
  },
});
