
import { defineComponent } from "vue";
import jsonData from "@/json/events.json";
import MyFlicker from "@/components/common-elements/MyFlicker.vue";
import MySelect from "@/components/common-elements/MySelect.vue";
import { Metatags } from "@/scripts/metatags";

export default defineComponent({
  name: "EventList",
  components: {
    MyFlicker,
    MySelect,
  },
  created() {
    this.updateEvents();
    this.setCountries();
    this.setMonths();
    this.showFlickers = true;
    Metatags.change({
      title: this.$router.currentRoute.value.meta.title,
      description:
        "AcroYoga Calendar to plan your acro Holidays/training/trips :P",
      image: "",
    });
  },
  data() {
    return {
      fullData: jsonData,
      allData: jsonData,
      adsData: jsonData,
      coworkData: jsonData,
      conventionData: jsonData,
      workshopsData: jsonData,
      ttData: jsonData,
      gatheringData: jsonData,
      festivalData: jsonData,
      retreatData: jsonData,
      jamData: jsonData,
      immersionData: jsonData,
      showFlickers: false,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      monthsList: [""],
      countriesList: [""],
      teachersList: [""],
      dataFilters: { country: "", month: "" },
    };
  },
  methods: {
    getEvent(array: Array<any>, keyType: string, keyValue: string | string[]) {
      const arrayItem = array.filter((item: any) => {
        return item[keyType] === keyValue;
      })[0];
      if (!arrayItem) {
        return false;
      }
      return true;
    },
    convertDate(date: string, start = true) {
      const dateExplode = date.split("-");
      let newDate = new Date(
        Number(dateExplode[0]),
        Number(dateExplode[1]) - 1,
        Number(dateExplode[2]),
        23,
        59,
        59
      );
      if (!start) {
        newDate = new Date(
          Number(dateExplode[0]),
          Number(dateExplode[1]) - 1,
          Number(dateExplode[2]) + 1,
          23,
          59,
          59
        );
      }
      return newDate;
    },
    filterEvents(event: any) {
      let dateStart = false;
      let country = false;
      let month = false;
      const dateStartExplode = event.start_at.split("-");
      let currentStartMonth = dateStartExplode[1] - 1;
      const dateEndExplode = event.end_at.split("-");
      let currentEndMonth = dateEndExplode[1] - 1;
      if (this.convertDate(event.start_at) > new Date()) {
        dateStart = true;
      }
      if (
        this.dataFilters.country === "" ||
        this.dataFilters.country === event.localization.country
      ) {
        country = true;
      }

      if (
        this.dataFilters.month === "" ||
        this.dataFilters.month === this.months[currentStartMonth] ||
        this.dataFilters.month === this.months[currentEndMonth]
      ) {
        month = true;
      }

      return dateStart && country && month;
    },
    setCountries() {
      const tempList: Array<string> = [];
      this.allData.forEach((element: any) => {
        tempList.push(element.localization.country);
      });
      this.countriesList = [...new Set(tempList)]
        .filter((element) => {
          return element != "";
        })
        .sort();
    },
    setMonths() {
      const tempList: Array<string> = [];
      this.months.forEach((element: any) => {
        this.fullData.forEach((event: any) => {
          const dateStartExplode = event.start_at.split("-");
          let currentStartMonth = dateStartExplode[1] - 1;
          const dateEndExplode = event.end_at.split("-");
          let currentEndMonth = dateEndExplode[1] - 1;
          if (
            element === this.months[currentStartMonth] ||
            element === this.months[currentEndMonth]
          ) {
            tempList.push(element);
          }
        });
      });
      this.monthsList = [...new Set(tempList)].filter((element) => {
        return element != "";
      });
    },
    onCountryChange(event: any) {
      this.dataFilters.country = event;
      this.updateEvents();
    },
    onMonthChange(event: any) {
      this.dataFilters.month = event;
      this.updateEvents();
    },

    updateEvents() {
      this.showFlickers = false;
      this.allData = this.fullData.filter((event) => {
        return this.filterEvents(event);
      });
      this.adsData = this.allData.filter((event) => {
        return event.is_highlight;
      });
      this.coworkData = this.allData.filter((event) => {
        return this.getEvent(event.categories, "slug", "cowork");
      });
      this.conventionData = this.allData.filter((event) => {
        return this.getEvent(event.categories, "slug", "convention");
      });
      this.workshopsData = this.allData.filter((event) => {
        return this.getEvent(event.categories, "slug", "workshop");
      });
      this.ttData = this.allData.filter((event) => {
        return this.getEvent(event.categories, "slug", "teacher_training");
      });
      this.gatheringData = this.allData.filter((event) => {
        return this.getEvent(event.categories, "slug", "gathering");
      });
      this.festivalData = this.allData.filter((event) => {
        return this.getEvent(event.categories, "slug", "festival");
      });
      this.retreatData = this.allData.filter((event) => {
        return this.getEvent(event.categories, "slug", "retreat");
      });
      this.jamData = this.allData.filter((event) => {
        return this.getEvent(event.categories, "slug", "jam");
      });
      this.immersionData = this.allData.filter((event) => {
        return this.getEvent(event.categories, "slug", "immersion");
      });
      this.showFlickers = true;
    },
  },
});
