import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7796bec6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar = _resolveComponent("calendar")!
  const _component_arrow_right_thick = _resolveComponent("arrow-right-thick")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", _ctx.cclass]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick())),
    type: _ctx.ctype,
    disabled: _ctx.disabledState
  }, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    (_ctx.icon == 'calendar')
      ? (_openBlock(), _createBlock(_component_calendar, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.icon == 'arrow-right')
      ? (_openBlock(), _createBlock(_component_arrow_right_thick, { key: 1 }))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}