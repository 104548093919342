import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const titleStart = "PMAY #";
const DEFAULT_TITLE = titleStart + " Plan My Acro";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: titleStart + " Events List" },
  },
  {
    path: "/event/:id",
    name: "Event",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/PageEvent.vue"),
    meta: { title: titleStart + " Event Information" },
  },
  {
    path: "/teacher/:id",
    name: "Teacher",
    props: (route) => ({ query: route.query.referenceId }),
    component: () =>
      import(/* webpackChunkName: "teacher" */ "../views/Teacher.vue"),
    meta: { title: titleStart + " Teacher Information" },
  },
  {
    path: "/addevent",
    name: "Add_Event",
    meta: { title: titleStart + " Add an Event" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "addevent" */ "../views/AddEvent.vue"),
  },
  {
    path: "/teachers",
    name: "TeachersList",
    meta: { title: titleStart + " Teachers List" },
    component: () =>
      import(/* webpackChunkName: "teachers" */ "../views/TeachersList.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to: any) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  document.title = to.meta.title || DEFAULT_TITLE;
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
