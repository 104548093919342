
import { defineComponent } from "vue";
import MyButton from "@/components/common-elements/MyButton.vue";
export default defineComponent({
  name: "MySelect",
  components: {
    MyButton,
  },
  created() {
    this.selectedText = this.text.toString();
    this.filteredData = this.data;
  },
  props: {
    text: { type: String, default: "Please Select" },
    data: Array,
  },
  methods: {
    toggleState() {
      this.show = !this.show;
    },
    onSelect(item: string | null, close = false) {
      if (!close) {
        this.selectedText = item != null ? item : this.text.toString();
        item = item != null ? item : "";
        this.$emit("onEvent", item);
      }
      this.toggleState();
    },
  },
  data() {
    return {
      selectedText: "",
      show: false,
      filteredData: this.data,
      filter: "",
    };
  },
});
