
import { defineComponent } from "vue";
import { Menu, Close } from "mdue";

export default defineComponent({
  name: "MyNavigation",
  components: {
    Menu,
    Close,
  },
  methods: {
    toggleMenu(toggle = true) {
      this.menuClass = this.menuClass === "open" || !toggle ? "" : "open";
    },
  },
  data() {
    return {
      menuClass: "",
    };
  },
});
