import { reactive } from "vue";

export const Metatags = reactive({
  change(options: any) {
    document.title = options.title + " # planmyacro.site";

    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", options.description);

    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", options.title);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", options.description);

    // This needs to be replaced by Image from DB
    const image = options.image || "default";
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", "/image/" + image + ".jpg");
  },
});
